import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FIREBASE_HOAPROVIDERS_COLLECTION, FIREBASE_USERSPROVIDERS_COLLECTION } from 'constants/defaultValuesFirebase';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from 'helpers/Firebase';
import { getStorage, ref, listAll } from 'firebase/storage';
import { type Provider } from 'types/Provider';
import type { EngagedProvider } from 'types/EngagedProvider';
import type { EngagedProviderReference } from 'types/EngagedProviderReference';

const initialState: ProvidersState = {
  providersList: [],
  loading: false,
  error: null,
};

// Interfaces
interface ProvidersState {
  providersList: Provider[];
  loading: boolean;
  error: string | null;
}
interface GetHoaProvidersParams {
  communityId: string;
}
interface GetEngagedProvidersByArrayParams {
  providersArray: EngagedProviderReference[];
}

interface GetCategoriesParams {
  communityId: string;
}

export interface ActivityOption {
  label: string;
  value: string;
  key: number;
}

export const getActivities = createAsyncThunk<ActivityOption[], GetCategoriesParams>(
  'providers/getActivities',
  async ({ communityId }) => {
    // TODO: Extract from backend
    const categories = [
      { label: 'Plumber', value: 'Plumber', key: 0 },
      { label: 'Gardener', value: 'Gardener', key: 1 },
      { label: 'Electrician', value: 'Electrician', key: 2 },
      { label: 'Carpenter', value: 'Carpenter', key: 3 },
      { label: 'Mechanic', value: 'Mechanic', key: 4 },
      { label: 'Handyman', value: 'Handyman', key: 5 },
    ];

    return categories;
  },
);

// Async Thunks
export const getProviderDetails = createAsyncThunk<
  Provider,
  { providerId: string },
  { rejectValue: { error: string } }
>('providers/getProviderDetails', async ({ providerId }, { rejectWithValue }) => {
  const details = await getDoc(doc(db, FIREBASE_USERSPROVIDERS_COLLECTION, providerId));
  const data = details.data();
  if (data == null) {
    return rejectWithValue({ error: `Provider ${providerId} does not exists` });
  }
  return data as Provider;
});

export const getProviderImages = createAsyncThunk(
  'providers/getProviderImages',
  async ({ providerId }: { providerId: string }, { rejectWithValue }) => {
    const storage = getStorage();
    const folderRef = ref(storage, `ProviderImages/${providerId}`);

    try {
      const res = await listAll(folderRef);
      const promises = res.items.map((itemRef, index) => {
        const url = `https://firebasestorage.googleapis.com/v0/b/${encodeURIComponent(
          // eslint-disable-next-line
          itemRef.bucket,
        )}/o/${encodeURIComponent(itemRef.fullPath)}?alt=media`;

        return { id: index, img: url };
      });

      const images = await Promise.all(promises);
      return images;
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);
export const getHoaProviders = createAsyncThunk<Provider[], GetHoaProvidersParams, { rejectValue: { error: string } }>(
  'providers/getHoaProviders',
  async ({ communityId }, { rejectWithValue }) => {
    try {
      const providersRef = collection(db, FIREBASE_HOAPROVIDERS_COLLECTION);
      const q = query(providersRef, where('communityId', '==', communityId));
      const providersSnapshot = await getDocs(q);
      const documents: Provider[] = providersSnapshot.docs.map((d) => {
        const data = d.data();
        const provider: Provider = {
          id: d.id,
          role: data.role ?? 0,
          community: data.community ?? [],
          activeCommunities: data.activeCommunities ?? [],
          creationDate: data.creationDate ?? '',
          email: data.email ?? '',
          img: data.img ?? '',
          name: data.name ?? '',
          phone: data.phone ?? '',
          services: data.services ?? [],
          temperature: 80,
          type: data.type ?? '',
          description: data.description ?? '',
          Reviews: data.Reviews ?? [],
          Web: data.Web ?? '',
        };
        return provider;
      });
      return documents;
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

export const getEngagedProvidersByArray = createAsyncThunk<
  EngagedProvider[],
  GetEngagedProvidersByArrayParams,
  { rejectValue: { error: string } }
>('providers/getEngagedProvidersByArray', async ({ providersArray }, { rejectWithValue }) => {
  try {
    if (providersArray.length === 0) {
      return [];
    }
    const onlyIdsArray = providersArray.map((provider) => provider.id);
    const providersRef = collection(db, FIREBASE_USERSPROVIDERS_COLLECTION);
    const q = query(providersRef, where('__name__', 'in', onlyIdsArray));
    const tempProvidersArray = await getDocs(q);

    const providersList: EngagedProvider[] = tempProvidersArray.docs.map((d) => {
      const data = d.data() as Omit<Provider, 'id'>;
      const matchingProvider = providersArray.find((provider) => provider.id === d.id);

      return {
        ...data,
        id: d.id,
        myTemperature: matchingProvider?.myTemperature ?? 80,
        isRecurrent: matchingProvider?.type === 'recurrentJob',
      };
    });

    return providersList;
  } catch (error) {
    return rejectWithValue({ error: (error as Error).message });
  }
});

// Slice
const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProviderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProviderDetails.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProviderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch provider details';
      })
      .addCase(getProviderImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderImages.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProviderImages.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as { error: string })?.error ?? 'Failed to fetch provider images';
      })
      .addCase(getHoaProviders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHoaProviders.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: Do we want a specific slice for HOA providers?
        state.providersList = action.payload;
      })
      .addCase(getHoaProviders.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as { error: string })?.error ?? 'Failed to fetch HOA providers';
      });
  },
});

export default providersSlice.reducer;
