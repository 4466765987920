import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { firebaseConfig } from 'constants/defaultValues';
import { defaultDebugHandler, defaultErrorHandler } from './errorHandlingHelper';

const environment = process.env.REACT_APP_ENVIRONMENT;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
// const database = firebase.database();
const functions = firebase.functions();
/**
 * Firebase analytics object if supported, otherwise null
 */
const analytics = firebase.analytics
  .isSupported()
  .then((supported) => (supported ? firebase.analytics() : null))
  .catch((e: Error) => {
    defaultErrorHandler('Error initializing analytics', e);
  });

if (environment === 'local') {
  functions.useEmulator('127.0.0.1', 5001);
  db.useEmulator('127.0.0.1', 5002);
  auth.useEmulator('http://localhost:5003');
}

defaultDebugHandler('Env variables', process.env);

export { auth, db, functions, analytics };
